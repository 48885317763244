import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      cacheBurst: "cart/cacheBurst"
    })
  },
  methods: {
    ...mapActions({
      setCategory: "category/setCategory"
    })
  },
  watch: {
    async cacheBurst(newValue, oldValue) {
      if (newValue != oldValue && typeof this.reload == "function") {
        this.reload();
      }
    }
  }
};
