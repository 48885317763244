<template>
  <div class="alergens">
    <div class="d-flex flex-row flex-wrap">
      <div v-for="(keys, index) in keyNames" :key="index" class="px-6 w-50 col">
        <h2>{{ keys }}</h2>
        <li v-for="(items, index) in grouped[keys]" :key="index">
          <span>{{ items.allergen }}</span>
        </li>
      </div>
    </div>
    <div class="px-6" v-if="other.length > 0">
      <!-- <h2>Allergeni Testo Libero</h2> -->
      <div v-for="(item, index) in other" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Alergens",
  props: {
    data: { required: true, type: Array }
  },
  data() {
    return {
      alergens: [],
      other: []
    };
  },
  computed: {
    grouped() {
      this.getAlergens();
      let ObjMap = {};

      this.alergens.forEach(element => {
        var makeKey = element.levelOfContainmentCode;
        if (!ObjMap[makeKey]) {
          ObjMap[makeKey] = [];
        }

        ObjMap[makeKey].push({
          allergen: element.allergenTypeCode
        });
      });
      return ObjMap;
    },
    keyNames() {
      return Object.keys(this.grouped);
    }
  },
  methods: {
    getAlergens() {
      this.data.forEach(element => {
        if (
          element.key ===
          ".TradeItem.TradeItemInformation[0].extension.AllergenInformationModule[0].AllergenRelatedInformation[0].Allergen"
        ) {
          this.alergens = element.content;
        } else this.other = element.content;
      });
    }
  }
};
</script>
<style scoped lang="scss">
.alergens {
  h2 {
    font-size: calc(0.9321428571rem + 0.2571428571vw);
    color: $primary;
    font-weight: 400;
    line-height: 1.2;
  }
  .col {
    min-width: 200px;
  }
}
</style>
