<template>
  <div class="product">
    <v-container v-if="product" class="product-detail">
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        :history="history"
        @selectImage="selectImage"
        :backButtonLabel="backButtonLabel"
      />
      <ProductDescription
        v-if="product.productId"
        :title="'product.details.components'"
        :product="product"
        :key="'product_description_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
      <ProductDescription
        v-if="product.productId"
        :title="'product.details.marketing'"
        :product="product"
        :key="'product_description3_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
      <ProductDescription
        v-if="product.productId"
        :title="'product.details.otherIformations'"
        :product="product"
        :key="'product_description2_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
      <ProductDescription
        v-if="product.productId"
        :title="'product.details.productCategories'"
        :product="product"
        :key="'product_description4_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
      <ProductDescription
        v-if="product.productId"
        :title="'product.details.usefulContacts'"
        :product="product"
        :key="'product_description5_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
      <h4 v-if="product.productId">
        {{ $t("product.whereIsItInCatalog") }}
      </h4>
      <div
        class="where-in-catalog d-flex flex-row justify-space-between align-center flex-wrap primary lighten-2 w-100"
        v-if="product.productId"
      >
        <div v-for="(bread, index) in whereInCatalog" :key="index" class="px-3">
          <div class="title">{{ titels[index] }}</div>
          <router-link class="text-decoration-none" :to="bread.to">{{
            bread.text
          }}</router-link>
        </div>
        <!-- {{ whereInCatalog }} -->
      </div>
    </v-container>
    <v-container v-if="showNoProductCard">
      <v-card
        class="d-flex align-center mb-6 justify-center"
        flat
        height="100"
        tile
      >
        <v-card class="pa-2" flat>
          <h3 class="font-weight-regular login-sub">
            {{ $t("products.noDetailProduct") }}
          </h3>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ProductDescription from "@/components/product/ProductDescription";
import ProductDetail from "@/components/product/ProductDetail.vue";
import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  data() {
    return {
      //mocks
      tabs: {
        product_description: {
          sale_description: "Acqua minerale"
        }
      },
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      titels: ["AREA_ECR1", "COMPARTO_ECR2", "FAMIGLIA_ECR3", "MERCATO_ECR4"],
      history: false,
      backButtonLabel: "navbar.navigation.back"
    };
  },
  components: {
    ProductDescription,
    ProductDetail
  },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    }),
    sharingTitle() {
      return this.product.name;
    },
    sharingDescription() {
      return (
        this.product.name +
        " " +
        this.product.description +
        " " +
        this.product.shortDescr
      );
    },
    getFullName() {
      return this._.join(
        this._.compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description,
          "in dettaglio"
        ]),
        " "
      );
    },
    vBreadcrumb() {
      let b = this._.clone(this.breadcrumb);
      if (this.product) {
        b.push({
          to: {
            name: "Product",
            params: {
              volantino: this.product.name
            }
          },
          text: this.product.name,
          exact: true
        });
      }
      return b;
    },
    whereInCatalog() {
      let b = this._.clone(this.breadcrumb);
      return b.splice(1);
    }
  },
  methods: {
    async getProduct() {
      var data = this.history
        ? await ProductService.gethistoryProductsById(this.slug)
        : await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      await this.resetFilters(this.category.slug);
      await this.getProduct();
      this.key += 1;
    }
  },
  async mounted() {
    if (this.$route.query.history) {
      this.history = this.$route.query.history;
    }
    this.slug = this.$route.params.slug;
    this.showNoProductCard = false;
    await this.getProduct();
    analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (this.$route.query.history) {
        this.history = this.$route.query.history;
      }
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        await this.getProduct();
        analyticsService.viewProductDetail(this.product);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(from);
      if (from.name === "Search") {
        vm.backButtonLabel = "navbar.navigation.backToSearch";
      } else if (from.name === "Category") {
        vm.backButtonLabel = "navbar.navigation.backToSearch";
      } else if (from.name === "CategoryMain") {
        vm.backButtonLabel = "navbar.navigation.backToCart";
      } else {
        vm.backButtonLabel = "navbar.navigation.back";
      }
    });
  },
  metaInfo() {
    return {
      title: this._.get(
        this.product,
        "metaData.product_seo.SEO_TITLE",
        this.getFullName
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this._.get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category.name +
              " in offerta e promozioni su GrosMarket Spesa Online con consegna a casa, ritiro in negozio e ritiro in locker."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this._.get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
