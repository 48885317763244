<template>
  <div>
    <table class="table table-striped w-100">
      <thead>
        <tr>
          <th v-for="(h, index) in headres" :key="index">{{ h }}</th>
        </tr>
      </thead>
      <tbody v-if="data">
        <tr v-for="(c, index) in columns" :key="index">
          <th scope="row">{{ c.Name }}</th>
          <td v-for="(cell, index) in c.Values" :key="index">{{ cell }}</td>
        </tr>
      </tbody>
    </table>
    <p v-for="(f, index) in footers" :key="index">{{ f }}</p>
  </div>
</template>
<script>
export default {
  name: "NutritionalInformation",
  data() {
    return {};
  },
  props: {
    data: { type: Object, require: true }
  },
  computed: {
    headres() {
      let h = ["Valori medi"];
      if (this.data && this.data.Headings) {
        h = h.concat(this.data.Headings);
      }
      return h;
    },
    columns() {
      let c = [];
      if (this.data && this.data.Nutrient) {
        c = this.data.Nutrient;
      }
      return c;
    },
    footers() {
      let f = [];
      if (this.data && this.data.Footers) {
        f = this.data.Footers;
      }
      return f;
    }
  }
};
</script>
