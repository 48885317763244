<template>
  <div>
    <div
      v-if="$vuetify.breakpoint.xs"
      class="back-buton-mobile"
      @click="$router.go(-1)"
    >
      <v-icon color="link" small>mdi-arrow-up-left</v-icon>
      {{
        history ? $t("navbar.navigation.backToHistory") : $t(backButtonLabel)
      }}
    </div>
    <div
      v-if="
        (history ||
          (product.pubState === 1 && product.web_enabled === 'false')) &&
          $vuetify.breakpoint.xs
      "
      class="history-product-mobile"
    >
      {{ history ? $t("common.historical") : $t("common.delisted") }}
    </div>
    <div class="d-flex justify-space-between">
      <div>
        <h1 class="primary--text pt-6">{{ productFullName }}</h1>
      </div>
      <div
        v-if="
          (history ||
            (product.pubState === 1 && product.web_enabled === 'false')) &&
            !$vuetify.breakpoint.xs
        "
        class="history-product"
      >
        <span>{{
          history ? $t("common.historical") : $t("common.delisted")
        }}</span>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center flex-wrap w-100 pb-4">
      <div
        v-if="!$vuetify.breakpoint.xs"
        @click="$router.go(-1)"
        class="noPrint"
      >
        <v-icon small>mdi-arrow-left</v-icon>
        <a class="link--text">
          {{
            history
              ? $t("navbar.navigation.backToHistory")
              : $t(backButtonLabel)
          }}</a
        >
      </div>
      <div class="d-flex flex-wrap btn-wraper">
        <!-- <div class="pl-2 noPrint">
          <v-btn
            color="primary"
            elevation="0"
            outlined
            @click="handleImageDownload"
            class="text-capitalize"
            >{{ $t("product.actions.getImages") }}</v-btn
          >
        </div>
        <div class="pl-2 noPrint">
          <v-btn
            color="primary"
            elevation="0"
            outlined
            class="text-capitalize"
            @click="handlePrint"
            >{{ $t("product.actions.print") }}</v-btn
          >
        </div>
        <div class="pl-2 noPrint">
          <v-btn color="primary" outlined class="text-capitalize"
            >{{ $t("common.download") }}
            <v-icon right dark>
              mdi-dots-horizontal
            </v-icon></v-btn
          >
        </div> -->
        <v-btn
          v-if="product.pubState === 1 && !(product.web_enabled === 'false')"
          class="noPrint cart-btn"
          color="primary"
          outlined
          @click="addToCarello(false)"
          >{{
            checkCart() ? $t("product.removeFromCart") : $t("product.addToList")
          }}</v-btn
        >
        <v-btn
          v-if="
            product.pubState === 1 &&
              product.web_enabled === 'false' &&
              hasImages
          "
          class="noPrint download-btn"
          color="primary"
          outlined
          @click="downloadOriginaleImg"
          >{{ $t("product.actions.downloadOriginalImg") }}</v-btn
        >
        <v-btn
          v-if="product.metaData.product_diff_history && product.pubState != 0"
          class="noPrint history-btn"
          color="primary"
          outlined
          @click="goToHistory()"
          >{{ $t("product.showHistoricalChanges") }}</v-btn
        >
      </div>
    </div>
    <h4 v-if="!$vuetify.breakpoint.smAndDown">
      Informazioni generali
    </h4>
    <div>
      <v-row v-if="product" class="border-primary">
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          order="1"
          order-sm="1"
          class="pa-3"
        >
          <v-card flat v-if="selectedImage" class="image-preview-wrapper">
            <ProductImages
              v-if="!$vuetify.breakpoint.smAndDown"
              :images="availableImages"
              :name="product.name"
              :code="productCode"
              :key="product.productId"
              :highlight="highlight"
              :heartKey="heartKey"
              @toggleFavourites="addToFavorites"
            />
            <ProductImagesSlider
              v-else
              :images="availableImages"
              :name="product.name"
            />
          </v-card>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          cols="12"
          order="2"
          order-sm="2"
          class="general-container d-flex justify-space-between flex-column"
        >
          <div class="d-flex flex-wrap justify-space-between">
            <div
              v-for="(p, index) in productInfo"
              :key="index"
              class="product-info w-45"
            >
              <div class="label">
                {{ $t(p.label) }}
              </div>
              <div
                class="info-details-full white-space-wrap"
                style="min-height: 20px;"
                v-html="p.data"
              ></div>
            </div>
          </div>
          <div class="product-info-icons d-flex flex-wrap">
            <div v-for="(p, index) in productInfoIcons" :key="index">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="p.icon"
                    :width="55"
                    :height="55"
                    :alt="p.label"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1 mr-1"
                  />
                </template>
                <span>{{ p.label }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ProductImages from "@/components/product/ProductImages.vue";
import ProductImagesSlider from "@/components/product/ProductImagesSlider.vue";
import LongPress from "vue-directive-long-press";

import productMixin from "~/mixins/product";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { formatProductCode } from "@/service/marketsuiteUtils";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product", "history", "backButtonLabel"],
  components: {
    ProductImages,
    ProductImagesSlider
  },
  mixins: [productMixin],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  data() {
    return {
      pubState: ["Attivo", "Disattivo"],
      imageDetailDialog: false
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated",
      hasImages: "cart/getHasImages",
      getProductPath: "app/getProductPath"
    }),
    productInfo() {
      let info = [];
      let content = this._.get(this.product, "version_date");
      if (content) {
        info.push({
          label: "common.versionDate",
          data: this.handleDate(content)
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.gtin`
      );
      if (content) {
        info.push({ label: "common.gtin", data: content });
      }
      // if (this.product.code)
      //   info.push({ label: "common.gtin", data: this.product.code });
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.TradeItemDescriptionModule[0].TradeItemDescriptionInformation.BrandNameInformation.brandName`
      );
      if (content) {
        info.push({ label: "advanceSearch.brand", data: content });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}["Ext-ManufacturerBrand"][0].value`
      );
      if (content) {
        info.push({
          label: "product.details.manufacturerBrand",
          data: content
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}["Ext-Company"][0].value`
      );
      if (content) {
        info.push({
          label: "product.details.subscriberName",
          data: content
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.TradeItemDescriptionModule[0].TradeItemDescriptionInformation.BrandNameInformation.subBrand`
      );
      if (content) {
        info.push({
          label: "product.details.submark",
          data: content
        });
      }

      if (this.findFeature != "") {
        info.push({
          label: "product.details.feature",
          data: this.findFeature
        });
      }

      if (this.productSalesName != "") {
        info.push({
          label: "product.details.salesName",
          data: this.productSalesName
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}['Ext-Brand'][0].value`
      );
      if (content) {
        info.push({
          label: "product.details.freeTextBrand",
          data: content
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.tradeItemUnitDescriptorCode`
      );
      if (content) {
        info.push({
          label: "Descrittore gerarchico",
          data: content
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.InformationProviderOfTradeItem.gln`
      );
      if (content) {
        info.push({
          label: "product.details.gln",
          data: content
        });
      }
      //content = this._.get(
      //  this.product,
      //  `${this.getProductPath}.TradeItem.InformationProviderOfTradeItem.partyName`
      //);
      //if (content) {
      //  info.push({
      //    label: "product.details.partyName",
      //    data: content
      //  });
      //}
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.GDSNTradeItemClassification.gpcCategoryCode`
      );
      if (content) {
        info.push({
          label: "product.details.gpcCategoryCode",
          data: content
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.DeliveryPurchasingInformationModule[0].DeliveryPurchasingInformation.startAvailabilityDateTime`
      );
      if (content) {
        info.push({
          label: "product.details.startAvailabilityDateTime",
          data: this.handleDate(content)
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.DeliveryPurchasingInformationModule[0].DeliveryPurchasingInformation.endAvailabilityDateTime`
      );
      if (content) {
        info.push({
          label: "product.details.endAvailabilityDateTime",
          data: this.handleDateTime(content)
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.TradeItemDataCarrierAndIdentificationModule[0].DataCarrier[0].dataCarrierTypeCode`
      );
      if (content) {
        info.push({
          label: "product.details.dataCarrierTypeCode",
          data: content
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.TradeItemDescriptionModule[0].TradeItemDescriptionInformation.functionalName`
      );
      if (content) {
        info.push({
          label: "product.details.functionalName",
          data: this.handleArray(content)
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.additionalTradeItemIdentification`
      );
      if (content) {
        if (typeof content[0] === "string" || content[0] instanceof String) {
          info.push({
            label: "product.details.additionalTradeItemIdentification",
            data: this.handleArray(content)
          });
        } else {
          info.push({
            label: "product.details.additionalTradeItemIdentification",
            data: this.handleAdditionalTradeItemIdentification(content)
          });
        }
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.GDSNTradeItemClassification.AdditionalTradeItemClassification[0].AdditionalTradeItemClassificationValue[0].additionalTradeItemClassificationCodeValue`
      );
      if (content) {
        info.push({
          label: "product.details.additionalTradeItemClassificationCodeValue",
          data: content
        });
      }
      return info;
    },
    productInfoIcons() {
      let info = [];
      let content;
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.isTradeItemABaseUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true" ? "Unità Base" : "No Unità Base",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/BaseUnit.svg"
              : "/infoIcons/Non_BaseUnit.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.VariableTradeItemInformationModule[0].VariableTradeItemInformation.isTradeItemAVariableUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Misure variabili"
              : "No Misure variabili",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Variabile.svg"
              : "/infoIcons/Non_Variabile.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.isTradeItemAConsumerUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Unità Consumatore"
              : "No Unità Consumatore",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Consumatore.svg"
              : "/infoIcons/Non_Consumatore.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.isTradeItemAnInvoiceUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Unità Fatturabile"
              : "No Unità Fatturabile",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Fatturabile.svg"
              : "/infoIcons/Non_Fatturabile.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.isTradeItemADespatchUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Unità di Spedizione"
              : "No Unità di Spedizione",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Spedibile.svg"
              : "/infoIcons/Non_Spedibile.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.isTradeItemAnOrderableUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Unità Ordinabile"
              : "No Unità Ordinabile",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Ordinabile.svg"
              : "/infoIcons/Non_Ordinabile.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.DisplayUnitInformation.isTradeItemADisplayUnit`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Unità Espositore"
              : "No Unità Espositore",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Display.svg"
              : "/infoIcons/Non_Display.svg"
        });
      }
      content = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.MarketingInformationModule[0].MarketingInformation[0].Season[0].isTradeItemSeasonal`
      );
      if (content) {
        info.push({
          label:
            content.toLowerCase() === "true"
              ? "Prodotto Stagionale"
              : "No Prodotto Stagionale",
          data: content,
          icon:
            content.toLowerCase() === "true"
              ? "/infoIcons/Stagionale.svg"
              : "/infoIcons/Non_Stagionale.svg"
        });
      }
      return info;
    },
    // productClasses() {
    //   return this.product &&
    //     this.product.productClasses &&
    //     this.product.productClasses.length
    //     ? this.product.productClasses.filter(p => p.productClassId !== 10078)
    //     : [];
    // },
    productCode() {
      return formatProductCode(this.product);
    },
    productFullName() {
      return this.product.name.replace(/&amp;/g, "&");
    },
    productSalesName() {
      let name = "";
      let p = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.TradeItemDescriptionModule[0].TradeItemDescriptionInformation.regulatedProductName`
      );
      if (Array.isArray(p)) {
        p.forEach(element => {
          if (!(element instanceof Object)) {
            name = name + ", " + element;
          }
        });
      }
      return name.substring(1);
    },
    findFeature() {
      let feature = "";
      let p = this._.get(
        this.product,
        `${this.getProductPath}.TradeItem.TradeItemInformation[0].extension.MarketingInformationModule[0].MarketingInformation`
      );
      if (Array.isArray(p)) {
        p.forEach(e => {
          if (e.tradeItemFeatureBenefit)
            feature = e.tradeItemFeatureBenefit.join("\r\n");
        });
      }

      return feature;
    },
    availableImages() {
      return this.product.media.filter(img => img.small != "");
    }
  },
  methods: {
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    handlePrint() {
      window.print();
    },
    goToHistory() {
      this.$router.push({
        name: "ProductHistory",
        params: { slug: this.product.main_product_id }
      });
    },
    handleDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    handleDateTime(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hh = "" + d.getUTCHours(),
        min = "" + d.getMinutes(),
        sec = "" + d.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hh.length < 2) hh = "0" + hh;
      if (min.length < 2) min = "0" + min;
      if (sec.length < 2) sec = "0" + sec;
      let formatedDate = [day, month, year].join("/");
      let formatedTime = [hh, min, sec].join(":");
      return formatedDate + " " + formatedTime;
    },
    handleArray(array) {
      return array.join("\r\n");
    },
    handleAdditionalTradeItemIdentification(array) {
      let value = [];
      array.forEach(e => {
        value.push(
          e["@value"] +
            " (" +
            e["@additionalTradeItemIdentificationTypeCode"] +
            ")"
        );
      });
      if (value.length > 0) {
        return this.handleArray(value);
      } else return null;
    }
  }
};
</script>
<style scoped lang="scss">
.back-button {
  width: 120%;
  padding: 15px;
  margin-top: -12px;
  margin-left: -12px;
  margin-right: -12px;
  cursor: pointer;
}
.btn-wraper {
  justify-content: end;
  @media screen and (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
}
.cart-btn {
  @media screen and (max-width: 768px) {
    width: 49.5%;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-bottom: 2px;
  }
  width: fit-content;
}
.history-btn {
  @media screen and (max-width: 768px) {
    width: 49.5%;
    margin-left: 0px;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-bottom: 2px;
    margin-left: 0px;
  }
  margin-left: 5px;
  width: fit-content;
  // width: 100%;
}
.history-product {
  position: relative;
  left: 56px;
  color: $white;
  transform: rotate(45deg);
  width: 0;
  height: 0;
  border-left: 65px solid transparent;
  border-right: 65px solid transparent;
  border-bottom: 65px solid $secondary;
  span {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 166%);
    font-weight: 400;
  }
}
.history-product-mobile {
  width: 100%;
  text-align: center;
  background-color: $secondary;
  color: $white;
  padding: 5px;
  margin-top: 5px;
}
.product-info-icons {
  overflow: auto;
  column-gap: 20px;
  row-gap: 5px;
}
</style>
