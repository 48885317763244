<template>
  <v-card elevation="0" v-if="tabs.length > 0">
    <h4 class="noPrint">{{ $t(title) }}</h4>
    <!-- v-tabs desktop -->
    <template v-if="!$vuetify.breakpoint.xs">
      <div class="custom-vertical-tab d-flex">
        <v-tabs
          vertical
          v-model="selectedTab"
          class="tabs-container-fix noPrint vertical-tabs"
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab v-for="tab in tabs" :key="tab.title">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          vertical
          v-model="selectedTab"
          class="flex-grow-1 noPrint white-space-wrap"
        >
          <v-tab-item v-for="tab in tabs" :key="tab.title">
            <div v-if="tab.cssClass === 'confezione'">
              <Packaging :data="tab" />
            </div>
            <div
              v-else
              v-for="item in tab.items"
              :key="item.title"
              class="tabs-content"
            >
              <h4 v-if="item.title">
                {{ item.title }}
              </h4>
              <div v-if="tab.cssClass === 'ingredienti'">
                <div v-for="path in item.paths" :key="path.key">
                  <span v-if="item.cssClass === 'ingredienti_multi_part'"
                    ><IngredientiMultiPart :data="path.content"
                  /></span>
                  <span v-else v-html="path.content"></span>
                </div>
              </div>
              <div v-if="tab.cssClass === 'allergens'">
                <div v-for="path in item.paths" :key="path.key">
                  <Alergens
                    v-if="item.cssClass == 'alleregeni _description'"
                    :data="item.paths"
                  />
                  <span v-else>{{ path.content }}</span>
                </div>
              </div>
              <div v-if="tab.cssClass === 'nutritional-information'">
                <div v-for="path in item.paths" :key="path.key">
                  <NutritionalInformation
                    v-if="item.cssClass == 'nutri-info'"
                    :data="path.content"
                  />
                  <FrontpackNutritionalInformation
                    v-else-if="item.cssClass == 'inf_fronte_confezione'"
                    :data="path.content"
                  />
                  <span v-else-if="item.cssClass == 'nutri-multi-part'">
                    <NutritionMultiPart :data="path.content" />
                  </span>
                  <span v-else>{{ path.content }}</span>
                </div>
              </div>
              <div v-if="tab.cssClass === 'common'">
                <div v-for="path in item.paths" :key="path.key">
                  <div v-for="path in item.paths" :key="path.key">
                    <span v-if="item.cssClass == 'hierarchy'"
                      ><Hierarchy
                        :data="path.content"
                        :barcode="product.barcode"
                    /></span>
                    <span v-else v-html="path.content"></span>
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
    <!-- v-expansion-panels mobile -->
    <template v-else>
      <v-expansion-panels class="mb-2" accordion flat>
        <v-expansion-panel
          v-for="tab in tabs"
          :key="tab.title"
          :class="tab.cssClass"
        >
          <v-expansion-panel-header>
            <h3 class="primary--text" v-html="tab.title"></h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="mt-2"
            v-if="tab.cssClass === 'confezione'"
          >
            <div>
              <Packaging :data="tab" />
            </div>
          </v-expansion-panel-content>
          <v-expansion-panel-content class="mt-2 white-space-wrap" v-else>
            <div v-for="item in tab.items" :key="item.title" class="pb-4">
              <h4 v-if="item.title">
                {{ item.title }}
              </h4>
              <div v-if="tab.cssClass === 'ingredienti'">
                <div v-for="path in item.paths" :key="path.key">
                  <span v-if="item.cssClass === 'ingredienti_multi_part'"
                    ><IngredientiMultiPart :data="path.content"
                  /></span>
                  <span v-else v-html="path.content">{{ path.content }}</span>
                </div>
              </div>
              <div v-if="tab.cssClass === 'allergens'">
                <div v-for="path in item.paths" :key="path.key">
                  <Alergens
                    v-if="item.cssClass == 'alleregeni _description'"
                    :data="item.paths"
                  />
                  <span v-else>{{ path.content }}</span>
                </div>
              </div>
              <div v-if="tab.cssClass === 'nutritional-information'">
                <div v-for="path in item.paths" :key="path.key">
                  <NutritionalInformation
                    v-if="item.cssClass == 'nutri-info'"
                    :data="path.content"
                  />
                  <FrontpackNutritionalInformation
                    v-else-if="item.cssClass == 'inf_fronte_confezione'"
                    :data="path.content"
                  />
                  <span v-else-if="item.cssClass == 'nutri-multi-part'">
                    <NutritionMultiPart :data="path.content" />
                  </span>
                  <span v-else>{{ path.content }}</span>
                </div>
              </div>
              <div v-if="tab.cssClass === 'common'">
                <div v-for="path in item.paths" :key="path.key">
                  <div v-for="path in item.paths" :key="path.key">
                    <span v-if="item.cssClass == 'hierarchy'"
                      ><Hierarchy
                        :data="path.content"
                        :barcode="product.barcode"
                    /></span>
                    <span v-else v-html="path.content"></span>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <!-- Print only -->
    <div class="printOnly pt-3 white-space-wrap">
      <h4>{{ $t(title) }}</h4>
      <div v-for="tab in tabs" :key="tab.title">
        <div v-if="tab.cssClass === 'confezione'">
          <h4>Confezione</h4>
          <Packaging :data="tab" />
        </div>
        <div v-else v-for="item in tab.items" :key="item.title" class="pb-4">
          <h4 v-if="item.title">
            {{ item.title }}
          </h4>
          <div v-if="tab.cssClass === 'ingredienti'">
            <div v-for="path in item.paths" :key="path.key">
              <span v-if="item.cssClass === 'ingredienti_multi_part'"
                ><IngredientiMultiPart :data="path.content"
              /></span>
              <span v-else v-html="path.content"></span>
            </div>
          </div>
          <div v-if="tab.cssClass === 'allergens'">
            <div v-for="path in item.paths" :key="path.key">
              <Alergens
                v-if="item.cssClass == 'alleregeni _description'"
                :data="item.paths"
              />
              <span v-else v-html="path.content"></span>
            </div>
          </div>
          <div v-if="tab.cssClass === 'nutritional-information'">
            <div v-for="path in item.paths" :key="path.key">
              <NutritionalInformation
                v-if="item.cssClass == 'nutri-info'"
                :product="product"
              />
              <FrontpackNutritionalInformation
                v-else-if="item.cssClass == 'inf_fronte_confezione'"
                :data="path.content"
              />
              <span v-else-if="item.cssClass == 'nutri-multi-part'">
                <NutritionMultiPart :data="path.content" />
              </span>
              <span v-else>{{ path.content }}</span>
            </div>
          </div>
          <div v-if="tab.cssClass === 'common'">
            <div v-for="path in item.paths" :key="path.key">
              <div v-for="path in item.paths" :key="path.key">
                <span v-if="item.cssClass == 'hierarchy'"
                  ><Hierarchy :data="path.content" :barcode="product.barcode"
                /></span>
                <span v-else v-html="path.content"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Alergens from "@/components/product/tabs/Alergens.vue";
import Packaging from "@/components/product/tabs/Packaging.vue";
import NutritionalInformation from "@/components/product/tabs/NutritionalInformation.vue";
//import CalculatedNutritionalInformation from "@/components/product/tabs/CalculatedNutritionalInf.vue";
import FrontpackNutritionalInformation from "./tabs/FrontpackNutritionalInformation.vue";
import IngredientiMultiPart from "./tabs/IngredientsMultiPart.vue";
import NutritionMultiPart from "./tabs/NutritionMultiPart.vue";
import Hierarchy from "./tabs/Hierarchy.vue";
import { mapGetters } from "vuex";
export default {
  name: "ProductDescription",
  components: {
    Alergens,
    Packaging,
    NutritionalInformation,
    //CalculatedNutritionalInformation,
    FrontpackNutritionalInformation,
    IngredientiMultiPart,
    NutritionMultiPart,
    Hierarchy
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: [
        {
          title: "Ingredienti",
          cssClass: "ingredienti",
          items: [
            {
              title: "",
              cssClass: "ingredienti_description",
              method: "handleArrayNoBullet",
              params: [],
              paths: ["['Ext-FormattedIngredients']"]
            },
            {
              title: "Sequenza Ingredienti",
              cssClass: "",
              method: "handleIngredientSequence",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeverageIngredientModule[0].FoodAndBeverageIngredient"
              ]
            },
            {
              title: "",
              cssClass: "ingredienti_multi_part",
              method: "handleMultiParts",
              params: ["Ext-FormattedIngredients"],
              paths: ["['Ext-Parts']"]
            },
            {
              title: "E' presente la Dichiarazione Allergeni",
              cssClass: "",
              method: "handleBooleanString",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AllergenInformationModule[0].AllergenRelatedInformation[0].isAllergenRelevantDataProvided"
              ]
            }
          ]
        },
        {
          title: "Informazioni nutrizionali",
          cssClass: "nutritional-information",
          items: [
            {
              title: "",
              cssClass: "nutri-info",
              paths: ["[Ext-Nutrition]"]
            },
            {
              title: "Informazioni nutrizionali fronte confezione",
              cssClass: "inf_fronte_confezione",
              paths: ["[Ext-FrontofPackNutrition]"]
            },
            {
              title: "",
              cssClass: "nutri-multi-part",
              method: "handleMultiPartsNutrition",
              params: [],
              paths: ["['Ext-Parts']"]
            }
          ]
        },
        {
          title: "Allergeni",
          cssClass: "allergens",
          items: [
            {
              title: "",
              cssClass: "alleregeni _description",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AllergenInformationModule[0].AllergenRelatedInformation[0].Allergen"
              ]
            },
            {
              title: "Altro testo allergeni",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AllergenInformationModule[0].AllergenRelatedInformation[0].allergenStatement"
              ]
            },
            {
              title: "Altri allergeni",
              cssClass: "",
              method: "handleArrayValueName",
              params: [],
              paths: ["['Ext-Allergens']"]
            }
          ]
        },
        {
          title: "Additivi e altri elementi",
          cssClass: "common",
          items: [
            {
              title: "Additivi/Nutrienti",
              cssClass: "",
              method: "hadleAdditivi",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.ProductInformationModule.ProductInformationDetail[0].ClaimDetail"
              ]
            },
            {
              title: "Testo Additivi",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeverageIngredientModule[0].additiveStatement"
              ]
            },
            {
              title: "Indicazioni nutrizionali e Indicazioni sulla salute",
              cssClass: "",
              method: "handleArrayValueName",
              params: [],
              paths: ["['Ext-Additives']"]
            },
            {
              title: "Dichiarazione Geneticamente Modificato",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FarmingAndProcessingInformationModule[0].TradeItemFarmingAndProcessing.geneticallyModifiedDeclarationCode"
              ]
            }
          ]
        }
      ],
      productDescriptionTabs2: [
        {
          title: "Confezione",
          cssClass: "confezione",
          items: [
            {
              title: "Dimensioni scaffale ",
              cssClass: "dimensioni_scaffale",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.width",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.width/@measurementUnitcode",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.height",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.height/@measurementUnitcode",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.depth",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.depth/@measurementUnitcode"
              ]
            },
            {
              title: "Larghezza",
              cssClass: "width",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.width",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.width/@measurementUnitcode",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.width/@measurementUnitCode"
              ]
            },
            {
              title: "Altezza",
              cssClass: "height",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.height",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.height/@measurementUnitcode",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.height/@measurementUnitCode"
              ]
            },
            {
              title: "Profondità",
              cssClass: "depth",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.depth",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.depth/@measurementUnitcode",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.depth/@measurementUnitCode"
              ]
            },
            {
              title: "Imballaggio",
              cssClass: "imballaggio",
              method: "handleArrayInLine",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.netContentStatement"
              ]
            },
            {
              title: "Tipo di imballaggio",
              cssClass: "pack_type",
              paths: [
                "['Ext-PackagingType']",
                ".TradeItem.TradeItemInformation[0].extension.PackagingInformationModule[0].Packaging[0].packagingTypeCode"
              ]
            },
            {
              title: "Peso lordo",
              cssClass: "peso_lordo",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.TradeItemWeight.grossWeight",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.TradeItemWeight.grossWeight/@measurementUnitCode"
              ]
            },
            {
              title: "Contenuto Netto",
              cssClass: "contenuto_netto",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.netContent"
              ]
            },
            {
              title: "Peso Sgocciolato",
              cssClass: "drained_weight",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.TradeItemWeight.drainedWeight",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.TradeItemWeight.drainedWeight/@measurementUnitcode",
                ".TradeItem.TradeItemInformation[0].extension.TradeItemMeasurementsModule[0].TradeItemMeasurements.TradeItemWeight.drainedWeight/@measurementUnitCode"
              ]
            },
            {
              title: "Tipo di materiale",
              cssClass: "packaging_material",
              method: "handleArrayNameValue",
              params: [],
              paths: ["['Ext-PackagingMaterialType']"]
            },
            {
              title: "Informazioni per la raccolta differenziata",
              cssClass: "recycling_info",
              paths: ["[Ext-RecyclingInfo]"]
            },
            {
              title: "Testo raccolta differenziata",
              cssClass: "consumer_recycling_instructions",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.ConsumerInstructionsModule[0].ConsumerInstructions.consumerRecyclingInstructions"
              ]
            },
            {
              title: "Tipo di unità",
              cssClass: "unit_type",
              paths: ["['Ext-UnitType'][0].value"]
            },
            {
              title: "Contenuto confezione",
              cssClass: "a_description",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemDescriptionModule[0].TradeItemDescriptionInformation.additionalTradeItemDescription"
              ]
            },
            {
              title: "Lingue sull'imballaggio",
              cssClass: "lingue_sull_imballaggio",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingMarkingModule[0].PackagingMarking.packagingMarkedLanguageCode"
              ]
            },
            {
              title: "Plastica nel prodotto",
              cssClass: "single_use_plastics",
              method: "handleArrayName",
              params: [],
              paths: ["['Ext-SingleUsePlastics']"]
            },
            {
              title: "Imballaggio Contrassegnato come Restituibile",
              cssClass: "is_returnable",
              method: "handleBooleanString",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingMarkingModule[0].PackagingMarking.isPackagingMarkedReturnable"
              ]
            },
            {
              title: "Il Trade Item è contrassegnato come Riciclabile?",
              cssClass: "is_recyclable",
              method: "handleBooleanString",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingMarkingModule[0].PackagingMarking.isTradeItemMarkedAsRecyclable"
              ]
            },
            {
              title: "Funzione Imballaggio",
              cssClass: "packagingFunctionCode",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingInformationModule[0].Packaging[0].packagingFunctionCode"
              ]
            },
            {
              title: "Caratteristica Imballaggio",
              cssClass: "packagingFeatureCode",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingInformationModule[0].Packaging[0].packagingFeatureCode"
              ]
            },
            {
              title: "Posizione dell'Antitaccheggio sul Trade Item",
              cssClass: "securityTagLocationCode",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SecurityTagInformationModule[0].SecurityTagInformation[0].securityTagLocationCode"
              ]
            },
            {
              title: "Tipo Antitaccheggio",
              cssClass: "securityTagTypeCode",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SecurityTagInformationModule[0].SecurityTagInformation[0].securityTagTypeCode"
              ]
            },
            {
              title: "Caratteristiche di Sostenibilità",
              cssClass: "packagingSustainabilityFeatureCode",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingInformationModule[0].Packaging[0].packagingSustainabilityFeatureCode"
              ]
            }
          ]
        },
        {
          title: "Uso e Conservazione",
          cssClass: "common",
          items: [
            {
              title: "Tipo di conservazione",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemTemperatureInformationModule[0].tradeItemTemperatureConditionTypeCode"
              ]
            },
            {
              title: "Temperatura di conservazione",
              cssClass: "",
              method: "handleTemperatura",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemTemperatureInformationModule[0].TradeItemTemperatureInformation[0]"
              ]
            },
            {
              title: "Umidità di conservazione",
              cssClass: "",
              method: "handleHumidity",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHumidityInformationModule[0].TradeItemHumidityInformation[0]"
              ]
            },
            {
              title: "Numero di lavaggi",
              cssClass: "",
              paths: ["['Ext-WashesNumber'][0].value"]
            },
            {
              title: "Numero di porzioni",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeveragePreparationServingModule[0].ServingQuantityInformation.numberOfServingsPerPackage"
              ]
            },
            {
              title: "Conservazione",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.ConsumerInstructionsModule[0].ConsumerInstructions.consumerStorageInstructions"
              ]
            },
            {
              title: "Durata dopo l'apertura",
              cssClass: "",
              method: "handleSafeToUseAfterOpening",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemLifespanModule[0].TradeItemLifespan"
              ]
            },
            {
              title: "Vita Residua Minima alla Produzione in Giorni",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemLifespanModule[0].TradeItemLifespan.minimumTradeItemLifespanFromTimeOfProduction"
              ]
            },
            {
              title: "Preparazione e uso",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.ConsumerInstructionsModule[0].ConsumerInstructions.consumerUsageInstructions"
              ]
            },
            {
              title: "Testo Utilizzi",
              cssClass: "",
              paths: ["[Ext-UsageOtherText][0].value"]
            },
            {
              title: "Ricette",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeveragePreparationServingModule[0].PreparationServing[0].recipe"
              ]
            },
            {
              title: "Limiti di età",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.MarketingInformationModule[0].MarketingInformation[0].TargetConsumer.targetConsumerAge"
              ]
            },
            {
              title: "Pannolini Taglia",
              cssClass: "string",
              paths: ["[Ext-NappyIndexedSize][0].value"]
            },
            {
              title: "Pannolini Età/Peso",
              // titlePath:
              //   "[Ext-NappySize][0].name",
              cssClass: "string",
              paths: ["[Ext-NappySize][0].value"]
            },
            {
              title: "Taglia",
              cssClass: "",
              method: "handleNonPackagedSize",
              params: ["EGGSIZE"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemSizeModule[0].NonPackagedSizeDimension"
              ]
            },
            {
              title: "Vita Residua Minima alla Consegna in Giorni",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemLifespanModule[0].TradeItemLifespan.minimumTradeItemLifespanFromTimeOfArrival"
              ]
            },
            {
              title: "Temperatura di Combustione",
              cssClass: "",
              method: "handleValueMeasurementUnit",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TransportationHazardousClassificationModule[0].TransportationClassification[0].RegulatedTransportationMode[0].HazardousInformationHeader[0].flashPointTemperature[0]"
              ]
            }
          ]
        },
        {
          title: "Origine",
          cssClass: "common",
          items: [
            {
              title: "Testi di Origine",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.provenanceStatement"
              ]
            },
            {
              title: "Paese di produzione",
              cssClass: "",
              method: "handleArrayValue",
              params: [],
              paths: ["['Ext-CountryOfOrigin']"]
            },
            {
              title: "Paese di produzione",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.countryOfOrigin[0].countryCode"
              ]
            },
            {
              title: "Paese di Attività",
              cssClass: "",
              method: "handleProductActivityDetailsCode",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.ProductActivityDetails"
              ]
            },
            {
              title: "Paese di confezionamento",
              cssClass: "",
              method: "handleProductActivityDetails",
              params: ["ASSEMBLY"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.ProductActivityDetails"
              ]
            },
            {
              title: "Bollo CE",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.RegulatedTradeItemModule[0].RegulatoryInformation[0].PermitIdentification[0].regulatoryPermitIdentification"
              ]
            },
            {
              title: "Zona di pesca",
              cssClass: "",
              method: "handleProductActivityDetails",
              params: ["CATCH_ZONE"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.ProductActivityDetails"
              ]
            },
            {
              title: "Luogo di nascita",
              cssClass: "",
              method: "handleProductActivityDetails",
              params: ["BIRTH"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.ProductActivityDetails"
              ]
            },
            {
              title: "Luogo di allevamento",
              cssClass: "",
              method: "handleProductActivityDetails",
              params: ["REARING"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.ProductActivityDetails"
              ]
            },
            {
              title: "Luogo di macellazione",
              cssClass: "",
              method: "handleProductActivityDetails",
              params: ["SLAUGHTER"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PlaceOfItemActivityModule[0].PlaceOfProductActivity.ProductActivityDetails"
              ]
            },
            {
              title: "Metodo Allevamento",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FarmingAndProcessingInformationModule[0].TradeItemFarmingAndProcessing.growingMethodCode"
              ]
            }
          ]
        },
        {
          title: "Sicurezza",
          cssClass: "common",
          items: [
            {
              title: "Avvertenza di Sicurezza",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SafetyDataSheetModule[0].SafetyDataSheetInformation[0].GHSDetail.PrecautionaryStatement[0].precautionaryStatementsDescription"
              ]
            },
            {
              title: "Safety Statements",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingMarkingModule[0].PackagingMarking.warningCopyDescription"
              ]
            },
            {
              title: "CLP Regulation",
              cssClass: "",
              method: "handleClp",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SafetyDataSheetModule[0].SafetyDataSheetInformation[0].GHSDetail"
              ]
            },
            {
              title: "A.I.S.E. Warnings",
              cssClass: "",
              method: "handleAise",
              params: [],
              paths: [""]
            },
            {
              title: "Codice Regolamento Merci Pericolose",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TransportationHazardousClassificationModule[0].TransportationClassification[0].RegulatedTransportationMode[0].HazardousInformationHeader[0].dangerousGoodsRegulationCode"
              ]
            },
            {
              title: "Codice Indicazione di Pericolo",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SafetyDataSheetModule[0].SafetyDataSheetInformation[0].GHSDetail.HazardStatement[0].hazardStatementsCode"
              ]
            },
            {
              title: "Descrizione Indicazione di Pericolo",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SafetyDataSheetModule[0].SafetyDataSheetInformation[0].GHSDetail.HazardStatement[0].hazardStatementsDescription"
              ]
            },
            {
              title: "Consigli di Prudenza",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.SafetyDataSheetModule[0].SafetyDataSheetInformation[0].GHSDetail.PrecautionaryStatement[0].precautionaryStatementsCode"
              ]
            }
          ]
        },
        {
          title: "Informazioni logistiche",
          cssClass: "common",
          items: [
            {
              title: "Numero Lotto",
              cssClass: "",
              method: "handleBooleanString",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingMarkingModule[0].PackagingMarking.hasBatchNumber"
              ]
            },
            {
              title: "Profondità Unità Logistica non-GTIN",
              cssClass: "",
              method: "handleMeasurements",
              params: ["depth"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NonGTINLogisticsUnitInformationModule[0].NonGTINLogisticsUnitInformation"
              ]
            },
            {
              title: "Peso Lordo Unità Logistica non-GTIN",
              cssClass: "",
              method: "handleMeasurements",
              params: ["grossWeight"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NonGTINLogisticsUnitInformationModule[0].NonGTINLogisticsUnitInformation"
              ]
            },
            {
              title: "Altezza Unità Logistica non-GTIN",
              cssClass: "",
              method: "handleMeasurements",
              params: ["height"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NonGTINLogisticsUnitInformationModule[0].NonGTINLogisticsUnitInformation"
              ]
            },
            {
              title: "Larghezza Unità Logistica non-GTIN",
              cssClass: "",
              method: "handleMeasurements",
              params: ["width"],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NonGTINLogisticsUnitInformationModule[0].NonGTINLogisticsUnitInformation"
              ]
            },
            {
              title: "Pallet Configurato Irregolarmente",
              cssClass: "",
              method: "handleBooleanString",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHierarchyModule[0].TradeItemHierarchy.isTradeItemPackedIrregularly"
              ]
            },
            {
              title: "Fattore di Impilabilità per Pallet non-GTIN",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NonGTINLogisticsUnitInformationModule[0].NonGTINLogisticsUnitInformation.logisticsUnitStackingFactor"
              ]
            },
            {
              title: "Numero di Strati per Pallet non-GTIN",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHierarchyModule[0].TradeItemHierarchy.quantityOfLayersPerPallet"
              ]
            },
            {
              title:
                "Numero di questo Prodotto Specifico in un'Unità Logistica non-GTIN",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHierarchyModule[0].TradeItemHierarchy.quantityOfTradeItemsPerPallet"
              ]
            },
            {
              title: "Numero di Colli per Strato (unità logistiche non-GTIN)",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHierarchyModule[0].TradeItemHierarchy.quantityOfTradeItemsPerPalletLayer"
              ]
            },
            {
              title: "Codice Tipo Pallet",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingInformationModule[0].Packaging[0].platformTypeCode"
              ]
            },
            {
              title: "Numero di Strati (unità logistiche GTIN)",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHierarchyModule[0].TradeItemHierarchy.quantityOfCompleteLayersContainedInATradeItem"
              ]
            },
            {
              title: "Numero di Colli per Strato (unità logistiche GTIN)",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHierarchyModule[0].TradeItemHierarchy.quantityOfTradeItemsContainedInACompleteLayer"
              ]
            },
            {
              title: "Fattore di Impilabilità",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHandlingModule[0].TradeItemHandlingInformation.TradeItemStacking[0].stackingFactor"
              ]
            },
            {
              title: "Codice Tipo Fattore di Impilabilità",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.TradeItemHandlingModule[0].TradeItemHandlingInformation.TradeItemStacking[0].stackingFactorTypeCode"
              ]
            },
            {
              title: "Quantità Minima Ordinabile",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DeliveryPurchasingInformationModule[0].DeliveryPurchasingInformation.orderQuantityMinimum"
              ]
            }
          ]
        },
        {
          title: "Gerarchia",
          cssClass: "common",
          items: [
            {
              title: "",
              cssClass: "hierarchy",
              paths: ["['Ext-AllineoHierarchies']"]
            }
          ]
        },
        {
          title: "Imposte e Dogane",
          cssClass: "common",
          items: [
            {
              title: "Tipo Imposta",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DutyFeeTaxInformationModule[0].DutyFeeTaxInformation[0].dutyFeeTaxTypeCode"
              ]
            },
            {
              title: "Il Trade Item è una Combinazione di Item?",
              cssClass: "",
              method: "handleBooleanString",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DutyFeeTaxInformationModule[0].DutyFeeTaxInformation[0].isTradeItemACombinationItem"
              ]
            },
            {
              title: "Categoria Imposta",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DutyFeeTaxInformationModule[0].DutyFeeTaxInformation[0].DutyFeeTax[0].dutyFeeTaxCategoryCode"
              ]
            },
            {
              title: "Aliquota Imposta",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DutyFeeTaxInformationModule[0].DutyFeeTaxInformation[0].DutyFeeTax[0].dutyFeeTaxRate"
              ]
            }
          ]
        },
        {
          title: "Altre Informazioni",
          cssClass: "common",
          items: [
            {
              title: "GTIN Sostituito",
              cssClass: "referencedGtin",
              paths: [".TradeItem.ReferencedTradeItem[0].gtin"]
            },
            {
              title: "Codice Tipo GTIN sostituito",
              cssClass: "referencedTradeItemTypeCode",
              paths: [
                ".TradeItem.ReferencedTradeItem[0].referencedTradeItemTypeCode"
              ]
            },
            {
              title: "Data di Decorrenza dell'Anagrafica",
              cssClass: "effective_date_time",
              method: "handleDate",
              params: [],
              paths: [
                ".TradeItem.TradeItemSynchronisationDates.effectiveDateTime"
              ]
            },
            {
              title: "Fattore di Protezione Solare (SPF)",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.HealthRelatedInformationModule[0].HealthRelatedInformation.sunProtectionFactor"
              ]
            }
          ]
        }
      ],
      productDescriptionTabs3: [
        {
          title: "Marketing/Promo",
          cssClass: "common",
          items: [
            {
              title: "Marketing prodotto",
              cssClass: "marketing_prodotto",
              method: "hadleMarketing",
              params: [1],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.MarketingInformationModule[0].MarketingInformation"
              ]
            },
            {
              title: "Marketing brand",
              cssClass: "marketing_brand",
              method: "hadleMarketing",
              params: [2],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.MarketingInformationModule[0].MarketingInformation"
              ]
            },
            {
              title: "Marketing produttore",
              cssClass: "",
              paths: ["[Ext-ManufacturerMarketing][0].value"]
            },
            {
              title: "Marketing supplementare",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.MarketingInformationModule[0].MarketingInformation[0].tradeItemStory"
              ]
            },
            {
              title: "Tipo di confezione promozionale",
              cssClass: "",
              paths: ["[Ext-PromotionalPackType][0].name"]
            },
            {
              title: "Testo Promozione",
              cssClass: "",
              method: "handleArrayValue",
              params: [],
              paths: ["[Ext-FurtherDescription]"]
            },
            {
              title: "Altre informazioni",
              cssClass: "",
              paths: ["[Ext-OtherInformation][0].value"]
            },
            {
              title: "Tipo di Promozione",
              cssClass: "",
              method: "handlePromotionType",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PromotionalItemInformationModule[0].PromotionalItemInformation"
              ]
            }
          ]
        },
        {
          title: "Loghi",
          cssClass: "common",
          items: [
            {
              title: "Loghi",
              cssClass: "",
              method: "hadleArray",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.PackagingMarkingModule[0].PackagingMarking.packagingMarkedLabelAccreditationCode"
              ]
            },
            {
              title: "Testo loghi",
              cssClass: "",
              method: "handleArrayValue",
              params: [],
              paths: ["[Ext-ThirdPartyLogoOtherText]"]
            },
            {
              title: "Altri Loghi Classificati",
              cssClass: "",
              method: "handleArrayName",
              params: [],
              paths: ["['Ext-ThirdPartyLogo']"]
            },
            {
              title: "Altri Regolamenti",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.RegulatedTradeItemModule[0].RegulatoryInformation[0].regulationTypeCode"
              ]
            }
          ]
        },
        {
          title: "Dieta/Indicazioni Nutrizionali",
          cssClass: "common",
          items: [
            {
              title: "Stile di vita",
              cssClass: "",
              method: "hadleStile_di_vita",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DietInformationModule[0].DietInformation.DietTypeInformation"
              ]
            },
            {
              title: "Indicazioni nutrizionali e Indicazioni sulla salute",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.ProductInformationModule.ProductInformationDetail[0].claimDescription"
              ]
            },
            {
              title: "Altri tipi dieta",
              cssClass: "array_name",
              method: "handleArrayName",
              params: [],
              paths: ["['Ext-Lifestyle']"]
            },
            {
              title: "Istruzioni per la Preparazione",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeveragePreparationServingModule[0].PreparationServing[0].preparationInstructions"
              ]
            },
            {
              title: "Codice Tipo di Preparazione",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeveragePreparationServingModule[0].PreparationServing[0].preparationTypeCode"
              ]
            },
            {
              title: "Descrizione della Base Nutritiva",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NutritionalInformationModule[0].NutrientHeader[0].nutrientBasisQuantityDescription"
              ]
            },
            {
              title: "Suggerimenti di Consumo",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeveragePreparationServingModule[0].PreparationServing[0].servingSuggestion"
              ]
            },
            {
              title: "Quantità Giornaliera Riferimento",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NutritionalInformationModule[0].NutrientHeader[0].dailyValueIntakeReference"
              ]
            },
            {
              title: "Percentuale della Quantità Giornaliera Nutrizionale",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NutritionalInformationModule[0].NutrientHeader[0].NutrientDetail[0].dailyValueIntakePercent"
              ]
            },
            {
              title:
                "Codice Precisione del Valore Percentuale Giornaliero dei Nutrienti",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NutritionalInformationModule[0].NutrientHeader[0].NutrientDetail[0].dailyValueIntakePercentMeasurementPrecisionCode"
              ]
            },
            {
              title: "Codice di Precisione Porzioni per Confezione",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.FoodAndBeveragePreparationServingModule[0].ServingQuantityInformation.numberOfServingsPerPackageMeasurementPrecisionCode"
              ]
            },
            {
              title: "Descrizione della Porzione",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.NutritionalInformationModule[0].NutrientHeader[0].servingSizeDescription"
              ]
            }
          ]
        }
      ],
      productDescriptionTabs4: [
        {
          title: "Ittico",
          cssClass: "common",
          items: [
            {
              title: "Metodo di pesca",
              cssClass: "",
              paths: ["['Ext-FishingMethod'][0].value"]
            },
            {
              title: "Nome Scientifico del Pescato",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DairyFishMeatPoultryItemModule[0].DairyFishMeatPoultryInformation.FishReportingInformation[0].speciesForFisheryStatisticsPurposesName"
              ]
            },
            {
              title: "Attrezzo da Pesca",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DairyFishMeatPoultryItemModule[0].DairyFishMeatPoultryInformation.FishReportingInformation[0].FishCatchInformation[0].catchMethodCode"
              ]
            },
            {
              title: "Metodo di Produzione Ittica",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DairyFishMeatPoultryItemModule[0].DairyFishMeatPoultryInformation.FishReportingInformation[0].FishCatchInformation[0].productionMethodForFishAndSeafoodCode"
              ]
            },
            {
              title: "Codice dello Stato dello Stoccaggio del Pesce",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DairyFishMeatPoultryItemModule[0].DairyFishMeatPoultryInformation.FishReportingInformation[0].FishCatchInformation[0].storageStateCode"
              ]
            },
            {
              title: "Zona di Pesca",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.DairyFishMeatPoultryItemModule[0].DairyFishMeatPoultryInformation.FishReportingInformation[0].FishCatchInformation[0].catchAreaCode"
              ]
            }
          ]
        },
        {
          title: "Petcare",
          cssClass: "common",
          items: [
            {
              title: "Tipo di animale",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AnimalFeedingModule[0].targetedConsumptionBy"
              ]
            },
            {
              title: "Taglia Animale",
              cssClass: "",
              method: "handleArrayValueName",
              params: [],
              paths: ["['Ext-PetType']"]
            },
            {
              title: "Alimentazione Animali",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AnimalFeedingModule[0].feedType"
              ]
            }
          ]
        },
        {
          title: "Vino e alcolici",
          cssClass: "common",
          items: [
            {
              title: "Tipo di alcol",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.alcoholBeverageTypeCode[0]"
              ]
            },
            {
              title: "Alcool per volume",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.percentageOfAlcoholByVolume"
              ]
            },
            {
              title: "Aspetto, aroma e gusto",
              cssClass: "aspetto_aroma_e_gusto",
              method: "handleAspetto",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.ProductCharacteristicsModule[0].ProductCharacteristics"
              ]
            },
            {
              title: "Colorazione del vino",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.alcoholicBeverageColourCode"
              ]
            },
            {
              title: "Effervescenza del vino",
              cssClass: "",
              paths: ["[Ext-WineEffervescence][0].name"]
            },
            {
              title: "Area definita",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.alcoholicBeverageSubregion"
              ]
            },
            {
              title: "Località Alcolici",
              cssClass: "",
              method: "handleArrayValue",
              params: [],
              paths: ["[Ext-DefinedArea]"]
            },
            {
              title: "Località Alcolici",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.originOfWineCode"
              ]
            },
            {
              title: "Avvertenze consumo",
              cssClass: "",
              method: "handleArrayValue",
              params: [],
              paths: ["['Ext-AlcoholByVolumeOtherText']"]
            },
            {
              title: "Unità alcolica altro testo",
              cssClass: "",
              paths: ["[Ext-AlcoholUnitsOtherText][0].value"]
            },
            {
              title: "Gradi del Malto",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.degreeOfOriginalWort"
              ]
            },
            {
              title: "Vintage (Annata)",
              cssClass: "",
              method: "handleArrayNoBullet",
              params: [],
              paths: [
                ".TradeItem.TradeItemInformation[0].extension.AlcoholInformationModule[0].AlcoholInformation.vintage"
              ]
            }
          ]
        }
      ],
      productDescriptionTabs5: [
        {
          title: "Contatti utili",
          cssClass: "common",
          items: [
            {
              title: "Indirizzo produttori",
              cssClass: "m_brand",
              paths: [".TradeItem.ManufacturerOfTradeItem[0].partyAddress"]
            },
            {
              title: "Restituire a",
              cssClass: "m_brand",
              paths: [
                ".TradeItem.TradeItemContactInformation[0].contactDescription[0]"
              ]
            },
            {
              title: "Assistenza telefonica",
              cssClass: "assistenza_tel",
              method: "hadleContact",
              params: ["TELEPHONE"],
              paths: [
                ".TradeItem.TradeItemContactInformation[0].TargetMarketCommunicationChannel[0].CommunicationChannel"
              ]
            },
            {
              title: "Assistenza email",
              cssClass: "assistenza_mail",
              method: "hadleContact",
              params: ["EMAIL"],
              paths: [
                ".TradeItem.TradeItemContactInformation[0].TargetMarketCommunicationChannel[0].CommunicationChannel"
              ]
            },
            {
              title: "Assistenza web",
              cssClass: "assistenza_web",
              method: "hadleContact",
              params: ["WEBSITE"],
              paths: [
                ".TradeItem.TradeItemContactInformation[0].TargetMarketCommunicationChannel[0].CommunicationChannel"
              ]
            },
            {
              title: "Numero di fax",
              cssClass: "assistenza_fax",
              method: "hadleContact",
              params: ["TELEFAX"],
              paths: [
                ".TradeItem.TradeItemContactInformation[0].TargetMarketCommunicationChannel[0].CommunicationChannel"
              ]
            },
            {
              title: "Indirizzo importatore",
              cssClass: "importatore",
              method: "handleImport",
              params: [],
              paths: [".TradeItem.PartyInRole"]
            },
            {
              title: "Indirizzo distributore",
              cssClass: "distributore",
              method: "handleDistribute",
              params: [],
              paths: [".TradeItem.PartyInRole"]
            },
            {
              title: "Indirizzo Contatto",
              cssClass: "",
              paths: [
                ".TradeItem.TradeItemContactInformation[0].contactAddress"
              ]
            },
            {
              title: "Nome Contatto",
              cssClass: "",
              paths: [".TradeItem.TradeItemContactInformation[0].contactName"]
            }
          ]
        }
      ]
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    },
    title: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      getProductPath: "app/getProductPath"
    }),
    tabs() {
      let tabs = [];
      let json = [];
      switch (this.title) {
        case "product.details.components":
          json = this.productDescriptionTabs;
          break;
        case "product.details.otherIformations":
          json = this.productDescriptionTabs2;
          break;
        case "product.details.marketing":
          json = this.productDescriptionTabs3;
          break;
        case "product.details.productCategories":
          json = this.productDescriptionTabs4;
          break;
        case "product.details.usefulContacts":
          json = this.productDescriptionTabs5;
          break;
      }
      json?.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = this._.get(
              this.product,
              this.getProductPath + pathMeta
            );
            if (parMeta.method && content) {
              content = this[parMeta.method](content, ...parMeta.params);
            }
            if (content) {
              paths.push({ key: pathMeta, content: content });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.titlePath
                ? this._.get(this.product, parMeta.titlePath, parMeta.title)
                : parMeta.title,
              paths: paths,
              cssClass: this._.get(
                parMeta,
                "cssClass",
                this._.kebabCase(parMeta.title)
              )
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: this._.get(
              tabMeta,
              "cssClass",
              this._.kebabCase(tabMeta.title)
            )
          });
        }
      });
      return tabs;
    }
  },
  methods: {
    hadleArray(array) {
      array = array.map(i => "• " + i);
      return array.join("\r\n");
    },
    hadleStile_di_vita(array) {
      array = array.map(i => "• " + i.dietTypeCode);
      return array.join("\r\n");
    },
    hadleAdditivi(array) {
      array = array.map(i => {
        return (
          i.claimTypeCode +
          " - " +
          i.claimElementCode
        );
      });
      return this.hadleArray(array);
    },
    hadleContact(array, code) {
      let value = null;
      array = array.forEach(i => {
        if (i.communicationChannelCode === code) {
          value = i.communicationValue;
        }
      });
      return value;
    },
    hadleMarketing(array, code) {
      let value = null;
      array = array.forEach(i => {
        if (code === 1 && i.tradeItemMarketingMessage) {
          value = i.tradeItemMarketingMessage[0];
        } else if (code === 2 && i.brandMarketingDescription) {
          value = i.brandMarketingDescription[0];
        }
      });
      return value;
    },
    checkTabTitle(title) {
      switch (title) {
        case "Ingredienti":
          return this.checkIngridientsTitle();
        case "Informazioni nutrizionali":
          return this.checkNutrientsTitle();
        default:
          return true;
      }
    },
    handleArrayNoBullet(array) {
      return array.join("\r\n");
    },
    handleImport(item) {
      let value = null;
      if (item.partyRoleCode === "IMPORTER") {
        value = item.partyAddress;
      }
      return value;
    },
    handleDistribute(item) {
      let value = null;
      if (item.partyRoleCode === "DISTRIBUTOR") {
        value = item.partyAddress;
      }
      return value;
    },
    handleAspetto(array) {
      let value = null;
      array.forEach(item => {
        if (
          item.productCharacteristicCode &&
          item.productCharacteristicCode ===
            "alcoholicBeverageCharacteristicDescription"
        ) {
          value = this.handleArrayNoBullet(
            item.productCharacteristicValueDescription
          );
        }
      });
      return value;
    },
    handleAise(item) {
      let array = [];
      if (item["Ext-A.I.S.E.Warnings"]) {
        item["Ext-A.I.S.E.Warnings"].forEach(e => {
          array.push(e.name);
        });
      }
      if (array.length > 0) {
        return this.handleArrayNoBullet(array);
      } else return null;
    },
    handleClp(item) {
      let value = null;
      let array = [];
      if (item.gHSSignalWordsCode) {
        value = item.gHSSignalWordsCode;
      }
      if (item.gHSSymbolDescriptionCode) {
        item.gHSSymbolDescriptionCode.forEach(e => {
          array.push(value ? value + " - " + e : e);
        });
      } else if (value) {
        array.push(value);
      }
      if (array.length > 0) {
        return this.handleArrayNoBullet(array);
      } else return null;
    },
    handleTemperatura(item) {
      let value = "";
      if (item.minimumTemperature)
        value += "Min " + item.minimumTemperature + "°C\n";
      if (item.maximumTemperature)
        value += "Max " + item.maximumTemperature + "°C";
      if (value == "") {
        return null;
      } else return value;
    },
    handleHumidity(item) {
      let value = "";
      if (item.minimumHumidityPercentage)
        value += "Min " + item.minimumHumidityPercentage + " %\n";
      if (item.maximumHumidityPercentage)
        value += "Max " + item.maximumHumidityPercentage + " %";
      if (value == "") {
        return null;
      } else return value;
    },
    handleNonPackagedSize(array, type) {
      let value = null;
      array.forEach(e => {
        if (e.sizeTypeCode === type) {
          value = e.descriptiveSizeDimension[0];
        }
      });
      return value;
    },
    handleProductActivityDetails(array, type) {
      let value = null;
      array.forEach(e => {
        if (e.productActivityTypeCode.trim() === type) {
          value =
            e.countryOfActivity &&
            e.countryOfActivity[0] &&
            e.countryOfActivity[0].countryCode
              ? e.countryOfActivity[0].countryCode
              : e.productActivityRegionDescription[0];
        }
      });
      return value;
    },
    handleProductActivityDetailsCode(array) {
      let value = [];
      array.forEach(e => {
        if (
          e.productActivityTypeCode &&
          e.productActivityTypeCode.trim() != "ASSEMBLY" &&
          e.productActivityTypeCode.trim() != "BIRTH" &&
          e.productActivityTypeCode.trim() != "CATCH_ZONE" &&
          e.productActivityTypeCode.trim() != "REARING" &&
          e.productActivityTypeCode.trim() != "SLAUGHTER"
        ) {
          value.push(
            e.productActivityTypeCode +
              " - " +
              (e.countryOfActivity &&
              e.countryOfActivity[0] &&
              e.countryOfActivity[0].countryCode
                ? e.countryOfActivity[0].countryCode
                : e.productActivityRegionDescription[0])
          );
        }
      });
      if (value.length > 0) {
        return this.handleArrayNoBullet(value);
      }
      return null;
    },
    handlePromotionType(array) {
      let info = [];
      array.forEach(e => {
        if (e.promotionTypeCode) info.push(e.promotionTypeCode);
      });
      if (info.length > 0) {
        return this.handleArrayNoBullet(info);
      } else return null;
    },
    handleSafeToUseAfterOpening(content) {
      let value = null;
      if (content.itemPeriodSafeToUseAfterOpening) {
        value = content.itemPeriodSafeToUseAfterOpening[0];
        if (
          content["itemPeriodSafeToUseAfterOpening/@timeMeasurementUnitCode"]
        ) {
          value +=
            " " +
            content["itemPeriodSafeToUseAfterOpening/@timeMeasurementUnitCode"];
        }
      }
      return value;
    },
    handleIngredientSequence(array) {
      let value = [];
      array.forEach(e => {
        if (e.ingredientSequence) {
          let percentage = "";
          if (e.ingredientContentPercentage)
            percentage = " " + e.ingredientContentPercentage + "%";
          value.push(
            e.ingredientSequence +
              " - " +
              e.ingredientName.join(", ") +
              percentage
          );
        }
      });
      if (value.length > 0) {
        return this.handleArrayNoBullet(value);
      } else return null;
    },
    handleBooleanString(item) {
      if (item.toLowerCase() === "true") {
        return "Sì";
      } else if (item.toLowerCase() === "false") {
        return "No";
      } else {
        return null;
      }
    },
    handleValueMeasurementUnit(item) {
      let value = null;
      if (item["@value"]) {
        value = item["@value"];
        if (item["@measurementUnitCode"]) {
          value += " " + item["@measurementUnitCode"];
        }
      }
      return value;
    },
    handleArrayValue(array) {
      let info = [];
      array.forEach(e => {
        info.push(e.value);
      });
      return this.handleArrayNoBullet(info);
    },
    handleArrayName(array) {
      let info = [];
      array.forEach(e => {
        info.push(e.name);
      });
      return this.handleArrayNoBullet(info);
    },
    handleArrayValueName(array) {
      let info = [];
      array.forEach(e => {
        info.push(e.value + " " + e.name);
      });
      return this.handleArrayNoBullet(info);
    },
    handleArrayNameValue(array) {
      let info = [];
      array.forEach(e => {
        info.push(e.name + " " + e.value);
      });
      return this.handleArrayNoBullet(info);
    },
    handleArrayInLine(array) {
      return array.join(" ");
    },
    handleDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    handleMeasurements(item, measure) {
      let value = null;
      if (item[measure]) {
        value = item[measure];
        if (item[measure + "/@measurementUnitCode"]) {
          value += " " + item[measure + "/@measurementUnitCode"];
        }
      }
      return value;
    },
    handleMultiParts(array, field) {
      let values = [];
      array.forEach(part => {
        if (part[field] != null)
          values.push({
            name: part["Ext-ComponentDescription"],
            values: part[field],
            key: field
          });
      });
      if (values.length > 0) {
        return values;
      } else return null;
    },
    handleMultiPartsNutrition(array) {
      let values = [];
      array.forEach(part => {
        if (
          part["Ext-Nutrition"] != null ||
          part["Ext-FrontofPackNutrition"] != null
        )
          values.push({
            name: part["Ext-ComponentDescription"],
            values: part["Ext-Nutrition"],
            valuesFrontPack: part["Ext-FrontofPackNutrition"]
          });
      });
      if (values.length > 0) {
        return values;
      } else return null;
    },
    checkIngridientsTitle() {
      if (this._.has(this.product, "['Ext-Parts']")) {
        let parts = this._.get(this.product, "['Ext-Parts']");
        return parts.some(part => {
          return part["Ext-FormattedIngredients"] != null;
        });
      } else if (this._.has(this.product, "['Ext-FormattedIngredients']"))
        return true;
      return false;
    },
    checkNutrientsTitle() {
      if (this._.has(this.product, "['Ext-Parts']")) {
        let parts = this._.get(this.product, "['Ext-Parts']");
        return parts.some(part => {
          return (
            part["Ext-Nutrition"] != null ||
            part["Ext-FrontofPackNutrition"] != null
          );
        });
      }
      return (
        this._.has(this.product, "[Ext-Nutrition]") ||
        this._.has(this.product, "[Ext-FrontofPackNutrition]")
      );
    }
  }
};
</script>
<style lang="scss">
.custom-vertical-tab .v-tabs {
  width: inherit;
}
.custom-vertical-tab .v-tab {
  min-width: 260px;
  max-width: 260px;
}
.tabs-container-fix {
  flex-grow: 0;
  padding: 0 10px;
}
.bullets {
  white-space: pre-line;
}
.white-space-wrap {
  white-space: pre-line;
}
</style>
