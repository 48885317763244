<template>
  <div>
    <v-expansion-panels class="mb-2" accordion flat multiple v-model="panel">
      <v-expansion-panel v-for="part in ingredientParts" :key="part.title">
        <v-expansion-panel-header>
          <h3 class="primary--text" v-html="part.title"></h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="custom-content" v-html="part.value">
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: "Ingredients-Multi-Part",
  props: {
    data: { required: true, type: Array }
  },
  data() {
    return {
      panel: []
    };
  },
  computed: {
    ingredientParts() {
      let i = [];
      this.data.forEach(part => {
        i.push({
          title: part["name"],
          value: this.handleArrayNoBullet(part["values"])
        });
      });
      return i;
    }
  },
  methods: {
    handleArrayNoBullet(array) {
      return array.join("\r\n");
    }
  }
};
</script>
<style scoped>
.custom-content {
  display: block;
  padding-left: 24px;
}
</style>
