<template>
  <div class="d-flex flex-wrap justify-space-between packaging">
    <div v-if="getByClass('dimensioni_scaffale')" class="w-45 mb-4">
      <h2>Dimensioni prodotto</h2>
      <div v-if="getByClass('width')">
        <span>{{ getByClass("width").title }}</span>
        <span v-if="getByClass('width').paths && getByClass('width').paths[0]">
          {{ getByClass("width").paths[0].content }}</span
        >
        <span v-if="getByClass('width').paths && getByClass('width').paths[1]">
          {{ getByClass("width").paths[1].content }}</span
        >
      </div>
      <div v-if="getByClass('height')">
        <span>{{ getByClass("height").title }}</span>
        <span
          v-if="getByClass('height').paths && getByClass('height').paths[0]"
        >
          {{ getByClass("height").paths[0].content }}</span
        >
        <span
          v-if="getByClass('height').paths && getByClass('height').paths[1]"
        >
          {{ getByClass("height").paths[1].content }}</span
        >
      </div>
      <div v-if="getByClass('depth')">
        <span>{{ getByClass("depth").title }}</span>
        <span v-if="getByClass('depth').paths && getByClass('depth').paths[0]">
          {{ getByClass("depth").paths[0].content }}</span
        >
        <span v-if="getByClass('depth').paths && getByClass('depth').paths[1]">
          {{ getByClass("depth").paths[1].content }}</span
        >
      </div>
    </div>
    <div v-if="getByClass('imballaggio')" class="w-45 mb-4">
      <h2>{{ getByClass("imballaggio").title }}</h2>
      <div
        v-if="
          getByClass('imballaggio').paths && getByClass('imballaggio').paths[0]
        "
      >
        <span>{{ getByClass("imballaggio").paths[0].content }}</span>
      </div>
    </div>
    <div v-if="getByClass('pack_type')" class="w-45 mb-4">
      <h2>{{ getByClass("pack_type").title }}</h2>
      <div>
        <span>{{ handleImballaggio() }}</span>
      </div>
    </div>
    <div v-if="getByClass('peso_lordo')" class="w-45 mb-4">
      <h2>{{ getByClass("peso_lordo").title }}</h2>
      <div>
        <span
          v-if="
            getByClass('peso_lordo').paths && getByClass('peso_lordo').paths[0]
          "
          >{{ getByClass("peso_lordo").paths[0].content }}</span
        >
        <span
          v-if="
            getByClass('peso_lordo').paths && getByClass('peso_lordo').paths[1]
          "
        >
          {{ getByClass("peso_lordo").paths[1].content }}</span
        >
      </div>
    </div>
    <div v-if="getByClass('contenuto_netto')" class="w-45 mb-4">
      <h2>{{ getByClass("contenuto_netto").title }}</h2>
      <div
        v-if="
          getByClass('contenuto_netto').paths &&
            getByClass('contenuto_netto').paths[0]
        "
      >
        <span>{{
          handleNetContent(getByClass("contenuto_netto").paths[0].content)
        }}</span>
      </div>
    </div>
    <div v-if="getByClass('drained_weight')" class="w-45 mb-4">
      <h2>{{ getByClass("drained_weight").title }}</h2>
      <div>
        <span
          v-if="
            getByClass('drained_weight').paths &&
              getByClass('drained_weight').paths[0]
          "
          >{{ getByClass("drained_weight").paths[0].content }}</span
        >
        <span
          v-if="
            getByClass('drained_weight').paths &&
              getByClass('drained_weight').paths[1]
          "
        >
          {{ getByClass("drained_weight").paths[1].content }}</span
        >
      </div>
    </div>
    <div v-if="getByClass('packaging_material')" class="w-45 mb-4">
      <h2>{{ getByClass("packaging_material").title }}</h2>
      <div
        v-if="
          getByClass('packaging_material').paths &&
            getByClass('packaging_material').paths[0]
        "
      >
        {{ getByClass("packaging_material").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('recycling_info')" class="w-45 mb-4">
      <h2>{{ getByClass("recycling_info").title }}</h2>
      <div>
        {{ handleInfRaccolta(getByClass("recycling_info").paths[0].content) }}
      </div>
    </div>
    <div v-if="getByClass('consumer_recycling_instructions')" class="w-45 mb-4">
      <h2>{{ getByClass("consumer_recycling_instructions").title }}</h2>
      <div
        v-if="
          getByClass('consumer_recycling_instructions').paths &&
            getByClass('consumer_recycling_instructions').paths[0]
        "
        v-html="getByClass('consumer_recycling_instructions').paths[0].content"
      ></div>
    </div>
    <div v-if="getByClass('unit_type')" class="w-45 mb-4">
      <h2>{{ getByClass("unit_type").title }}</h2>
      <div
        v-if="getByClass('unit_type').paths && getByClass('unit_type').paths[0]"
      >
        {{ getByClass("unit_type").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('lingue_sull_imballaggio')" class="w-45 mb-4">
      <h2>{{ getByClass("lingue_sull_imballaggio").title }}</h2>
      <div
        v-if="
          getByClass('lingue_sull_imballaggio').paths &&
            getByClass('lingue_sull_imballaggio').paths[0]
        "
      >
        {{ getByClass("lingue_sull_imballaggio").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('a_description')" class="w-45 mb-4">
      <h2>{{ getByClass("a_description").title }}</h2>
      <div
        v-if="
          getByClass('a_description').paths &&
            getByClass('a_description').paths[0]
        "
      >
        {{ getByClass("a_description").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('single_use_plastics')" class="w-45 mb-4">
      <h2>{{ getByClass("single_use_plastics").title }}</h2>
      <div
        v-if="
          getByClass('single_use_plastics').paths &&
            getByClass('single_use_plastics').paths[0]
        "
      >
        {{ getByClass("single_use_plastics").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('is_returnable')" class="w-45 mb-4">
      <h2>{{ getByClass("is_returnable").title }}</h2>
      <div
        v-if="
          getByClass('is_returnable').paths &&
            getByClass('is_returnable').paths[0]
        "
      >
        {{ getByClass("is_returnable").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('is_recyclable')" class="w-45 mb-4">
      <h2>{{ getByClass("is_recyclable").title }}</h2>
      <div
        v-if="
          getByClass('is_recyclable').paths &&
            getByClass('is_recyclable').paths[0]
        "
      >
        {{ getByClass("is_recyclable").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('packagingFunctionCode')" class="w-45 mb-4">
      <h2>{{ getByClass("packagingFunctionCode").title }}</h2>
      <div
        v-if="
          getByClass('packagingFunctionCode').paths &&
            getByClass('packagingFunctionCode').paths[0]
        "
      >
        {{ getByClass("packagingFunctionCode").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('packagingFeatureCode')" class="w-45 mb-4">
      <h2>{{ getByClass("packagingFeatureCode").title }}</h2>
      <div
        v-if="
          getByClass('packagingFeatureCode').paths &&
            getByClass('packagingFeatureCode').paths[0]
        "
      >
        {{ getByClass("packagingFeatureCode").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('securityTagLocationCode')" class="w-45 mb-4">
      <h2>{{ getByClass("securityTagLocationCode").title }}</h2>
      <div
        v-if="
          getByClass('securityTagLocationCode').paths &&
            getByClass('securityTagLocationCode').paths[0]
        "
      >
        {{ getByClass("securityTagLocationCode").paths[0].content }}
      </div>
    </div>
    <div v-if="getByClass('securityTagTypeCode')" class="w-45 mb-4">
      <h2>{{ getByClass("securityTagTypeCode").title }}</h2>
      <div
        v-if="
          getByClass('securityTagTypeCode').paths &&
            getByClass('securityTagTypeCode').paths[0]
        "
      >
        {{ getByClass("securityTagTypeCode").paths[0].content }}
      </div>
    </div>
    <div
      v-if="getByClass('packagingSustainabilityFeatureCode')"
      class="w-45 mb-4"
    >
      <h2>{{ getByClass("packagingSustainabilityFeatureCode").title }}</h2>
      <div
        v-if="
          getByClass('packagingSustainabilityFeatureCode').paths &&
            getByClass('packagingSustainabilityFeatureCode').paths[0]
        "
      >
        {{ getByClass("packagingSustainabilityFeatureCode").paths[0].content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Packaging",
  props: {
    data: { required: true, type: Object }
  },
  computed: {
    test() {
      return this.data.items;
    }
  },
  methods: {
    getByTitle(title) {
      return this.data.items.filter(item => item.title === title)[0]?.paths;
    },
    getByClass(cssClass) {
      return this.data.items.filter(item => item.cssClass === cssClass)[0];
    },
    handleArray(array) {
      return array.join("\r\n");
    },
    handleInfRaccolta(array) {
      let value = "";
      array.forEach(item => {
        if (
          item.fieldName &&
          item.fieldName === "Informazioni per la raccolta differenziata"
        ) {
          value += item.name + " - " + item.value + "\n";
        }
      });
      return value;
    },
    handleArrayValue(array) {
      let info = [];
      array.forEach(e => {
        info.push(e.value);
      });
      return this.handleArray(info);
    },
    handleImballaggio() {
      let data = this.getByClass("pack_type").paths;
      if (data[0].key == "['Ext-PackagingType']") {
        return this.handleArrayValue(data[0].content);
      } else {
        return data[0].content;
      }
    },
    handleNetContent(array) {
      let info = [];
      array.forEach(e => {
        info.push(
          e["@value"] +
            " " +
            (e["@measurementUnitcode"]
              ? e["@measurementUnitcode"]
              : e["@measurementUnitCode"])
        );
      });
      return this.handleArray(info);
    }
  }
};
</script>
<style scoped lang="scss">
.packaging {
  h2 {
    display: block;
    font-size: 0.75rem;
    color: $secondary;
    text-transform: uppercase;
    font-weight: 300;
  }
  span {
    font-weight: 300;
    line-height: 1.625;
    color: var(--v-grey-darken3);
  }
  div {
    font-weight: 300;
    line-height: 1.625;
    color: var(--v-grey-darken3);
  }
}
</style>
