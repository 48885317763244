<template>
  <div class="custom-tree">
    <div v-for="(hierarchy, index) in data" :key="index">
      <v-treeview :items="[hierarchy]" open-all hoverable>
        <template v-slot:label="{ item }">
          <div v-if="item.itemsInParent">{{ item.itemsInParent }} items</div>
          <div class="d-flex flex-wrap py-1">
            <div
              class="gtin"
              :class="{ 'product-gtin': item.gtin === barcode }"
            >
              {{ item.gtin }}
            </div>
            <v-chip class="" color="primary">{{ item.type }}</v-chip>
            <v-expansion-panels
              v-if="item.width && item.height && item.depth"
              flat
              class="hierarchy-expansion-panel"
            >
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions>
                  <template v-slot:default="{ open }">
                    <div class="d-flex">
                      <v-icon>
                        <template v-if="open">mdi-chevron-down</template>
                        <template v-else>mdi-chevron-right</template>
                      </v-icon>
                      <span class="header-title">Dimensioni Prodotto</span>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="item.width && item.widthMeasurementUnit">
                    Larghezza {{ item.width }} {{ item.widthMeasurementUnit }}
                  </div>
                  <div v-if="item.height && item.heightMeasurementUnit">
                    Altezza {{ item.height }} {{ item.heightMeasurementUnit }}
                  </div>
                  <div v-if="item.depth && item.depthMeasurementUnit">
                    Profondità {{ item.depth }} {{ item.depthMeasurementUnit }}
                  </div>
                  <div v-if="item.weight && item.weightMeasurementUnit">
                    Peso Lordo {{ item.weight }}
                    {{ item.weightMeasurementUnit }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </v-treeview>
    </div>
  </div>
</template>
<script>
export default {
  name: "Hierarchy",
  props: {
    data: { required: true, type: Array },
    barcode: { required: true, type: String }
  }
};
</script>
<style scoped lang="scss">
.custom-tree {
  &::v-deep .v-treeview-node__toggle {
    pointer-events: none;
    color: transparent;
  }
  .gtin {
    padding: 5px 0px;
    margin-right: 5px;
  }
  .product-gtin {
    font-weight: bold;
  }
  .hierarchy-expansion-panel {
    width: auto;
    margin-left: 5px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    &::v-deep .v-expansion-panel-header {
      min-height: 0;
      padding: 5px 4px;
    }
    &::v-deep .v-expansion-panel-content__wrap {
      padding: 8px 4px 8px 28px;
    }
    &::v-deep .v-expansion-panel {
      background: transparent;
    }
    .header-title {
      align-self: center;
      padding-top: 2.6px;
    }
  }
}
</style>
