<template>
  <div v-if="data">
    <div v-for="(r, index) in info.Reference" :key="index">
      <p class="descs">{{ r.Description }}</p>
      <p class="head">{{ r.Headers[0] }}</p>
      <div class="d-flex flex-wrap justify-center">
        <div class="label" v-for="(l, index) in r.Lozenges" :key="index">
          <span class="title"
            >{{ l.Name }}
            <p v-for="(q, index) in l.Quantities" :key="index">
              {{ q.Value }}{{ q.Unit.Name }}
            </p></span
          >
          <span class="bottom"
            ><p v-if="l.Percentage">
              <span
                v-if="l.Percentage.Qualifier && l.Percentage.Qualifier.Text"
                >{{ l.Percentage.Qualifier.Text }}</span
              ><span v-if="l.Percentage.Value">{{ l.Percentage.Value }}</span
              >%
            </p></span
          >
        </div>
      </div>
      <div class="mt-4">
        <p v-for="(f, index) in r.Footers" :key="index">{{ f }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FrontNutritionalInformation",
  data() {
    return {};
  },
  props: {
    data: { type: Object, require: true }
  },
  computed: {
    info() {
      let i = this.data;
      return i;
    }
  }
};
</script>
<style scoped lang="scss">
.label {
  position: relative;
  width: 100px;
  height: 140px;
  text-align: center;
  border: 1px solid $secondary;
  margin-bottom: 2px;
  margin-right: 4px;
  background-color: $secondary;
  color: $white;
  border-radius: 100px/50px;
  overflow: hidden;
  min-width: 100px;
  // zoom: 0.65;
  transform: scale(0.7);
  margin: -14px;
}

.title {
  display: inline-block;
  margin-top: 14px;
  font-size: smaller;
  line-height: 1.5rem !important;
  p {
    margin: 0px;
    font-size: smaller;
  }
}

.bottom {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 50px;
  padding-top: 18px;
  color: $secondary;
  line-height: 1px;
  font-weight: bold;
  border-top: 1px solid $secondary;
  border-radius: 100px/50px;
  background-color: #fff;
}
.descs {
  text-align: center;
  font-weight: 500;
  margin-bottom: 5px;
}
.head {
  text-align: center;
}
</style>
